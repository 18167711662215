import React, { useEffect, useState } from 'react';
import { ExperienceControllerApi, Experience, Tag, Configuration, EnvControllerApi } from 'api';
import { BASE_PATH } from 'api/base';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
//import { InteractiveBrowserCredential } from '@azure/identity';
// we're using these objects from the storage sdk - there are others for different needs
import { BlobServiceClient, BlobItem } from '@azure/storage-blob';

interface CardContentProps {
	experience: Experience;
	sasToken: string;
}

function CardContent({ experience, sasToken }: CardContentProps) {
	const [media, setMedia] = useState<string | null>(null);
	const [illustrationUrl, setIllustrationUrl] = useState<string | null>(null);

	const navigate = useNavigate();
	const token = localStorage.getItem('token');
	if (!token) {
		navigate('/login');
		return null;
	}
	const configuration = new Configuration({
		accessToken: token,
	});
	const experienceController = new ExperienceControllerApi(configuration);

	const blobServiceClient = new BlobServiceClient(`https://showcasemedias.blob.core.windows.net/?${sasToken}`);
	const illustrationContainerClient = blobServiceClient.getContainerClient('illustrations');
	// set the illustrationUrl when the experience changes
	useEffect(() => {
		if (!experience.illustrationFilename) {
			return;
		}
		const blobClient = illustrationContainerClient.getBlobClient(experience.illustrationFilename);
		setIllustrationUrl(blobClient.url);
	}, [experience, token]);

	return (
		<div className="card mt-4">
			<div className="card-body align-items-sm-center">
				<h4 className="card-title text-center">{experience.title}</h4>
				<p className="card-text text-center flex-sm-grow-1">{experience.description}</p>
				{illustrationUrl && <img src={illustrationUrl} alt={experience.title} className="img-fluid mw-100" />}
				<div className="d-flex flex-wrap">
					<button type="button" className="btn btn-primary btn-sm disabled my-2">
						{experience.multiIndustry ? 'All' : experience.industry?.name}
					</button>
				</div>
				{experience.tags && (
					<div className="d-flex flex-wrap">
						{experience.tags?.map((tag: Tag) => (
							<button key={tag.id} type="button" className="btn btn-secondary btn-sm disabled my-2">
								{tag.name}
							</button>
						))}
					</div>
				)}
			</div>
		</div>
	);
}

export default CardContent;
