import { PropsWithChildren, ReactNode } from 'react';
import Header from 'components/layout/Header';
import Footer from 'components/layout/Footer';

interface PageProps {
	children: ReactNode;
	activeNav?: string;
	hideNav?: boolean;
}

const Page = ({ children, activeNav, hideNav = false }: PageProps) => {
	return (
		<div>
			<Header activeNav={activeNav || ''} hideNav={hideNav} />
			<div style={{ maxWidth: '1500px' }} className="container mb-4">
				{children}
			</div>
			<Footer />
		</div>
	);
};

export default Page;
