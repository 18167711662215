const Footer = () => {
	// get current year
	const currentYear = new Date().getFullYear();
	return (
		<footer className="footer" role="contentinfo" aria-label="Footer">
			<div className="container">
				<div className="d-lg-flex align-items-center justify-content-between">
					<div className="copyright">© Capgemini, {currentYear}. All rights reserved.</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
