// ExperiencesList.tsx
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ExperienceControllerApi, IndustryControllerApi, Experience, Industry, Tag, Configuration } from 'api';
import { useNavigate } from 'react-router-dom';
import Page from 'components/layout';
import Spinner from 'components/ui/Spinner';
import moment from 'moment';

const ExperiencesList = () => {
	const [experiences, setExperiences] = useState<Experience[]>([]);
	const [loading, setLoading] = useState(true);

	const navigate = useNavigate();
	const token = localStorage.getItem('token');
	if (!token) {
		navigate('/login');
		return null;
	}
	// TODO make sure it's admin

	const configuration = new Configuration({
		accessToken: token,
	});
	const api = new ExperienceControllerApi(configuration);

	const fetchExperiences = () => {
		api
			.findExperiences()
			.then((response) => {
				setExperiences(response.data || []);
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	useEffect(() => {
		fetchExperiences();
	}, []);

	const getIndustry = (experience: Experience) => {
		return experience.multiIndustry ? '(All)' : experience.industry?.name || '';
	};
	const getTagNames = (tags: Tag[] | undefined) => {
		return tags?.map((tag) => tag.name).join(', ') || '';
	};

	return (
		<Page>
			<div>
				<h1>Manage experiences</h1>
				{loading && <Spinner />}
				{!loading && (
					<>
						<table className="table table-striped mt-4">
							<thead>
								<tr>
									<th scope="col">Tite</th>
									<th scope="col">Industry</th>
									<th scope="col">Tags</th>
									<th scope="col">Updated at</th>
									<th scope="col">Edit</th>
								</tr>
							</thead>
							<tbody>
								{experiences.map((experience) => (
									<tr key={experience.id}>
										<td>{experience.title}</td>
										<td>{getIndustry(experience)}</td>
										<td>{getTagNames(experience.tags)}</td>
										<td>{moment(experience.updatedAt).format('MMMM Do YYYY, h:mm:ss a')}</td>
										<td>
											<h5>
												<Link className="link-secondary" to={`/admin/experiences/${experience.id}`}>
													Edit
												</Link>
											</h5>
										</td>
									</tr>
								))}
							</tbody>
						</table>

						<div className="d-flex justify-content-end">
							<Link to="/admin/experiences/new">
								<button type="button" className="btn btn-primary">
									Create New Experience
								</button>
							</Link>
						</div>
					</>
				)}
			</div>
		</Page>
	);
};

export default ExperiencesList;
