import React, { useEffect, useState } from 'react';
import { useAuth } from 'hooks/AuthProvider';
import Page from 'components/layout';
import {
	ExperienceControllerApi,
	IndustryControllerApi,
	Experience,
	Industry,
	Tag,
	Configuration,
	EnvControllerApi,
} from 'api';
import ExperienceCard from './ExperienceCard';
import IndustryOption from './IndustryOption';
import { useNavigate } from 'react-router-dom';
import Spinner from 'components/ui/Spinner';

const Dashboard = () => {
	const auth = useAuth();
	const [loading, setLoading] = useState(true);
	const [industriesLoading, setIndustriesLoading] = useState(true);
	const [experiences, setExperiences] = useState<Experience[]>([]);
	const [industrys, setIndustries] = useState<Industry[]>([]);
	const [selectedIndustry, setSelectedIndustry] = useState<string>('');
	const [sasToken, setSasToken] = useState<string>('');

	const navigate = useNavigate();
	const token = localStorage.getItem('token');
	if (!token) {
		navigate('/login');
		return null;
	}
	const configuration = new Configuration({
		accessToken: token,
	});
	const api = new ExperienceControllerApi(configuration);
	const industryApi = new IndustryControllerApi(configuration);

	const fetchExperiences = (industryId: string | '') => {
		api
			.findExperiences({ industryId })
			.then((response) => {
				setExperiences(response.data || []);
				setLoading(false);
			})
			.catch((error) => {
				if (error?.response?.status === 401) {
					auth.logOut();
					navigate('/login');
				}
				console.error(error);
			});
	};

	useEffect(() => {
		fetchExperiences(selectedIndustry);
	}, [selectedIndustry]);

	useEffect(() => {
		// populate the industries dropdown
		industryApi
			.getIndustries()
			.then((response) => {
				setIndustries(response.data || []);
				setIndustriesLoading(false);
			})
			.catch((error) => {
				console.error(error);
			});

		// get the SAS token
		const envController = new EnvControllerApi(configuration);
		envController
			.getEnv()
			.then((env) => {
				setSasToken(env.data.sasToken || '');
			})
			.catch((error) => {
				console.error(error);
			});
		api;
	}, []);

	const handleIndustryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setSelectedIndustry(event.target.value);
	};

	const resetSelection = () => {
		setSelectedIndustry('');
	};

	return (
		<Page activeNav="Experiences">
			<h1 className="text-center">Generative AI Corner</h1>
			{(loading || industriesLoading) && <Spinner />}
			{!industriesLoading && (
				<div className="row">
					<div className="col-sm-10 col-md-4 form-floating my-3">
						<select
							className="form-select"
							id="singleDropdown"
							autoComplete="off"
							onChange={handleIndustryChange}
							value={selectedIndustry}
						>
							<option value="">(All)</option>
							{industrys.map((industry) => (
								<IndustryOption key={industry.id} industry={industry} />
							))}
						</select>
						<label htmlFor="singleDropdownn">Select your industry</label>
					</div>
					<div className="col-sm-2 col-md-8 my-3 py-2">
						<button
							onClick={resetSelection}
							type="button"
							className="btn btn-secondary btn-sm btn-icon-only"
							title="Reset the filter"
						>
							<span className="btn-close btn-inner--icon"></span>
						</button>
					</div>
				</div>
			)}

			{!loading && experiences.length === 0 && (
				<div className="alert alert-warning" role="alert">
					No experiences found
				</div>
			)}
			{!loading && experiences.length > 0 && (
				<div className="row">
					{/* Render the fetched experiences */}
					{experiences.map((experience) => (
						<ExperienceCard key={experience.id} experience={experience} sasToken={sasToken} />
					))}
				</div>
			)}
		</Page>
	);
};

export default Dashboard;
