import { useContext, createContext, useState, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { User } from 'api';
import { BASE_PATH } from 'api/base';
import { AuthControllerApi, Experience, AuthControllerApiAuthenticateRequest, Configuration } from 'api';

interface AuthContextType {
	token: string | null;
	user: User | null;
	loginAction: (data: LoginData) => Promise<void>;
	logOut: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
	children: ReactNode;
}

interface LoginData {
	email: string;
	password: string;
}

const AuthProvider = ({ children }: AuthProviderProps) => {
	const [user, setUser] = useState(null);
	const [token, setToken] = useState(localStorage.getItem('token') || '');
	const navigate = useNavigate();
	const loginAction = async (data: LoginData) => {
		try {
			const configuration = new Configuration({});
			const api = new AuthControllerApi(configuration);
			const request: AuthControllerApiAuthenticateRequest = {
				user: {
					email: data.email,
					password: data.password,
					updatedAt: '',
				},
			};
			const response = await api.authenticate(request);

			if (response.data && response.data.token) {
				// 				setUser(response.data.user);
				setToken(response.data.token);
				localStorage.setItem('token', response.data.token);
				navigate('/dashboard');
				return;
			}
			throw new Error('response.message');
		} catch (err) {
			console.error(err);
		}
	};

	const logOut = () => {
		setUser(null);
		setToken('');
		localStorage.removeItem('token');
		navigate('/login');
	};

	return <AuthContext.Provider value={{ token, user, loginAction, logOut }}>{children}</AuthContext.Provider>;
};

export default AuthProvider;

export const useAuth = (): AuthContextType => {
	const context = useContext(AuthContext);
	if (!context) {
		throw new Error('useAuth must be used within an AuthProvider');
	}
	return context;
};
