import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from 'reportWebVitals';
import 'assets/design-system.css';
import 'assets/custom.css';

import Login from 'pages/Login';
import AuthProvider from 'hooks/AuthProvider';
import PrivateRoute from 'hooks/PrivateRoute';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Dashboard from 'pages/dashboard';
import ExperienceView from 'pages/experiences';
import ExperienceList from 'pages/admin/experience/ExperienceList';
import ExperienceDetails from 'pages/admin/experience/ExperienceDetails';
import UserList from 'pages/admin/user/UserList';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<AuthProvider>
				<Routes>
					<Route path="/test" element={<Login />} />
					<Route path="/login" element={<Login />} />
					<Route element={<PrivateRoute />}>
						<Route path="/dashboard" element={<Dashboard />} />
					</Route>
					<Route element={<PrivateRoute />}>
						<Route path="/experiences/:id/view" element={<ExperienceView />} />
					</Route>
					<Route element={<PrivateRoute />}>
						<Route path="/admin/experiences" element={<ExperienceList />} />
					</Route>
					<Route element={<PrivateRoute />}>
						<Route path="/admin/experiences/:id" element={<ExperienceDetails />} />
					</Route>
					<Route element={<PrivateRoute />}>
						<Route path="/admin/users" element={<UserList />} />
					</Route>
					{/* Other routes */}
					<Route path="/" element={<Navigate to="/dashboard" />} />
					<Route path="*" element={<p>Path not resolved</p>} />
				</Routes>
			</AuthProvider>
		</BrowserRouter>
	</React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
