import React from 'react';
import { Industry } from 'api';

interface IndustryOptionProps {
	industry: Industry;
}

function IndustryOption({ industry }: IndustryOptionProps) {
	return <option value={industry.id}>{industry.name}</option>;
}

export default IndustryOption;
