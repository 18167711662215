import React from 'react';
import { Experience, Tag } from 'api';
import { Link } from 'react-router-dom';
import CardContent from './CardContent';

interface ExperienceCardProps {
	experience: Experience;
	sasToken: string;
}

function ExperienceCard({ experience, sasToken }: ExperienceCardProps) {
	return (
		<div className="col-md-6 col-lg-4 col-xl-3" key={experience.id}>
			{experience.shouldOpenInNewTab ? (
				<a href={experience.url} target="_blank" rel="noopener noreferrer">
					<CardContent experience={experience} sasToken={sasToken} />
				</a>
			) : (
				<Link to={`/experiences/${experience.id}/view`}>
					<CardContent experience={experience} sasToken={sasToken} />
				</Link>
			)}
		</div>
	);
}

export default ExperienceCard;
