/* tslint:disable */
/* eslint-disable */
/**
 * Case Management Service
 * Claim Event Information
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Env
 */
export interface Env {
    /**
     * 
     * @type {string}
     * @memberof Env
     */
    'sasToken'?: string;
}
/**
 * 
 * @export
 * @interface Experience
 */
export interface Experience {
    /**
     * 
     * @type {string}
     * @memberof Experience
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Experience
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Experience
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Experience
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof Experience
     */
    'description'?: string;
    /**
     * 
     * @type {Industry}
     * @memberof Experience
     */
    'industry'?: Industry;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof Experience
     */
    'tags'?: Array<Tag>;
    /**
     * 
     * @type {string}
     * @memberof Experience
     */
    'contactEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof Experience
     */
    'illustrationFilename'?: string;
    /**
     * 
     * @type {string}
     * @memberof Experience
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof Experience
     */
    'videoFilename'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Experience
     */
    'multiIndustry'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Experience
     */
    'shouldOpenInNewTab'?: boolean;
}
/**
 * 
 * @export
 * @interface GrantedAuthority
 */
export interface GrantedAuthority {
    /**
     * 
     * @type {string}
     * @memberof GrantedAuthority
     */
    'authority'?: string;
}
/**
 * 
 * @export
 * @interface Industry
 */
export interface Industry {
    /**
     * 
     * @type {string}
     * @memberof Industry
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Industry
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Industry
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Industry
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    'token'?: string;
    /**
     * 
     * @type {number}
     * @memberof LoginResponse
     */
    'expiresIn'?: number;
}
/**
 * 
 * @export
 * @interface Tag
 */
export interface Tag {
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'role'?: UserRoleEnum;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {Array<GrantedAuthority>}
     * @memberof User
     */
    'authorities'?: Array<GrantedAuthority>;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'accountNonLocked'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'credentialsNonExpired'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'accountNonExpired'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'username'?: string;
}

export const UserRoleEnum = {
    Admin: 'ADMIN',
    Viewer: 'VIEWER'
} as const;

export type UserRoleEnum = typeof UserRoleEnum[keyof typeof UserRoleEnum];


/**
 * AuthControllerApi - axios parameter creator
 * @export
 */
export const AuthControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticate: async (user: User, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('authenticate', 'user', user)
            const localVarPath = `/api/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register: async (user: User, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('register', 'user', user)
            const localVarPath = `/api/auth/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthControllerApi - functional programming interface
 * @export
 */
export const AuthControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticate(user: User, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticate(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async register(user: User, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.register(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthControllerApi - factory interface
 * @export
 */
export const AuthControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticate(user: User, options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.authenticate(user, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register(user: User, options?: any): AxiosPromise<User> {
            return localVarFp.register(user, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for authenticate operation in AuthControllerApi.
 * @export
 * @interface AuthControllerApiAuthenticateRequest
 */
export interface AuthControllerApiAuthenticateRequest {
    /**
     * 
     * @type {User}
     * @memberof AuthControllerApiAuthenticate
     */
    readonly user: User
}

/**
 * Request parameters for register operation in AuthControllerApi.
 * @export
 * @interface AuthControllerApiRegisterRequest
 */
export interface AuthControllerApiRegisterRequest {
    /**
     * 
     * @type {User}
     * @memberof AuthControllerApiRegister
     */
    readonly user: User
}

/**
 * AuthControllerApi - object-oriented interface
 * @export
 * @class AuthControllerApi
 * @extends {BaseAPI}
 */
export class AuthControllerApi extends BaseAPI {
    /**
     * 
     * @param {AuthControllerApiAuthenticateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public authenticate(requestParameters: AuthControllerApiAuthenticateRequest, options?: AxiosRequestConfig) {
        return AuthControllerApiFp(this.configuration).authenticate(requestParameters.user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthControllerApiRegisterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public register(requestParameters: AuthControllerApiRegisterRequest, options?: AxiosRequestConfig) {
        return AuthControllerApiFp(this.configuration).register(requestParameters.user, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EnvControllerApi - axios parameter creator
 * @export
 */
export const EnvControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnv: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/env`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EnvControllerApi - functional programming interface
 * @export
 */
export const EnvControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EnvControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnv(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Env>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnv(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EnvControllerApi - factory interface
 * @export
 */
export const EnvControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EnvControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnv(options?: any): AxiosPromise<Env> {
            return localVarFp.getEnv(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EnvControllerApi - object-oriented interface
 * @export
 * @class EnvControllerApi
 * @extends {BaseAPI}
 */
export class EnvControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvControllerApi
     */
    public getEnv(options?: AxiosRequestConfig) {
        return EnvControllerApiFp(this.configuration).getEnv(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EnvJsControllerApi - axios parameter creator
 * @export
 */
export const EnvJsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        envConfig: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/env.js`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EnvJsControllerApi - functional programming interface
 * @export
 */
export const EnvJsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EnvJsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async envConfig(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.envConfig(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EnvJsControllerApi - factory interface
 * @export
 */
export const EnvJsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EnvJsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        envConfig(options?: any): AxiosPromise<string> {
            return localVarFp.envConfig(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EnvJsControllerApi - object-oriented interface
 * @export
 * @class EnvJsControllerApi
 * @extends {BaseAPI}
 */
export class EnvJsControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvJsControllerApi
     */
    public envConfig(options?: AxiosRequestConfig) {
        return EnvJsControllerApiFp(this.configuration).envConfig(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExperienceControllerApi - axios parameter creator
 * @export
 */
export const ExperienceControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Experience} experience 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExperience: async (experience: Experience, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'experience' is not null or undefined
            assertParamExists('createExperience', 'experience', experience)
            const localVarPath = `/api/experiences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(experience, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExperience: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteExperience', 'id', id)
            const localVarPath = `/api/experiences/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [industryId] 
         * @param {Array<string>} [tagIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findExperiences: async (industryId?: string, tagIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/experiences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (industryId !== undefined) {
                localVarQueryParameter['industryId'] = industryId;
            }

            if (tagIds) {
                localVarQueryParameter['tagIds'] = tagIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperienceById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getExperienceById', 'id', id)
            const localVarPath = `/api/experiences/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {Experience} experience 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExperience: async (id: string, experience: Experience, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateExperience', 'id', id)
            // verify required parameter 'experience' is not null or undefined
            assertParamExists('updateExperience', 'experience', experience)
            const localVarPath = `/api/experiences/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(experience, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExperienceControllerApi - functional programming interface
 * @export
 */
export const ExperienceControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExperienceControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Experience} experience 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createExperience(experience: Experience, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Experience>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createExperience(experience, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteExperience(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteExperience(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [industryId] 
         * @param {Array<string>} [tagIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findExperiences(industryId?: string, tagIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Experience>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findExperiences(industryId, tagIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExperienceById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Experience>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExperienceById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {Experience} experience 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateExperience(id: string, experience: Experience, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Experience>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateExperience(id, experience, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExperienceControllerApi - factory interface
 * @export
 */
export const ExperienceControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExperienceControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {Experience} experience 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExperience(experience: Experience, options?: any): AxiosPromise<Experience> {
            return localVarFp.createExperience(experience, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExperience(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteExperience(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [industryId] 
         * @param {Array<string>} [tagIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findExperiences(industryId?: string, tagIds?: Array<string>, options?: any): AxiosPromise<Array<Experience>> {
            return localVarFp.findExperiences(industryId, tagIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperienceById(id: string, options?: any): AxiosPromise<Experience> {
            return localVarFp.getExperienceById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {Experience} experience 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExperience(id: string, experience: Experience, options?: any): AxiosPromise<Experience> {
            return localVarFp.updateExperience(id, experience, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createExperience operation in ExperienceControllerApi.
 * @export
 * @interface ExperienceControllerApiCreateExperienceRequest
 */
export interface ExperienceControllerApiCreateExperienceRequest {
    /**
     * 
     * @type {Experience}
     * @memberof ExperienceControllerApiCreateExperience
     */
    readonly experience: Experience
}

/**
 * Request parameters for deleteExperience operation in ExperienceControllerApi.
 * @export
 * @interface ExperienceControllerApiDeleteExperienceRequest
 */
export interface ExperienceControllerApiDeleteExperienceRequest {
    /**
     * 
     * @type {string}
     * @memberof ExperienceControllerApiDeleteExperience
     */
    readonly id: string
}

/**
 * Request parameters for findExperiences operation in ExperienceControllerApi.
 * @export
 * @interface ExperienceControllerApiFindExperiencesRequest
 */
export interface ExperienceControllerApiFindExperiencesRequest {
    /**
     * 
     * @type {string}
     * @memberof ExperienceControllerApiFindExperiences
     */
    readonly industryId?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof ExperienceControllerApiFindExperiences
     */
    readonly tagIds?: Array<string>
}

/**
 * Request parameters for getExperienceById operation in ExperienceControllerApi.
 * @export
 * @interface ExperienceControllerApiGetExperienceByIdRequest
 */
export interface ExperienceControllerApiGetExperienceByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof ExperienceControllerApiGetExperienceById
     */
    readonly id: string
}

/**
 * Request parameters for updateExperience operation in ExperienceControllerApi.
 * @export
 * @interface ExperienceControllerApiUpdateExperienceRequest
 */
export interface ExperienceControllerApiUpdateExperienceRequest {
    /**
     * 
     * @type {string}
     * @memberof ExperienceControllerApiUpdateExperience
     */
    readonly id: string

    /**
     * 
     * @type {Experience}
     * @memberof ExperienceControllerApiUpdateExperience
     */
    readonly experience: Experience
}

/**
 * ExperienceControllerApi - object-oriented interface
 * @export
 * @class ExperienceControllerApi
 * @extends {BaseAPI}
 */
export class ExperienceControllerApi extends BaseAPI {
    /**
     * 
     * @param {ExperienceControllerApiCreateExperienceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienceControllerApi
     */
    public createExperience(requestParameters: ExperienceControllerApiCreateExperienceRequest, options?: AxiosRequestConfig) {
        return ExperienceControllerApiFp(this.configuration).createExperience(requestParameters.experience, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExperienceControllerApiDeleteExperienceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienceControllerApi
     */
    public deleteExperience(requestParameters: ExperienceControllerApiDeleteExperienceRequest, options?: AxiosRequestConfig) {
        return ExperienceControllerApiFp(this.configuration).deleteExperience(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExperienceControllerApiFindExperiencesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienceControllerApi
     */
    public findExperiences(requestParameters: ExperienceControllerApiFindExperiencesRequest = {}, options?: AxiosRequestConfig) {
        return ExperienceControllerApiFp(this.configuration).findExperiences(requestParameters.industryId, requestParameters.tagIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExperienceControllerApiGetExperienceByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienceControllerApi
     */
    public getExperienceById(requestParameters: ExperienceControllerApiGetExperienceByIdRequest, options?: AxiosRequestConfig) {
        return ExperienceControllerApiFp(this.configuration).getExperienceById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExperienceControllerApiUpdateExperienceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienceControllerApi
     */
    public updateExperience(requestParameters: ExperienceControllerApiUpdateExperienceRequest, options?: AxiosRequestConfig) {
        return ExperienceControllerApiFp(this.configuration).updateExperience(requestParameters.id, requestParameters.experience, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IndustryControllerApi - axios parameter creator
 * @export
 */
export const IndustryControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIndustries: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/industries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IndustryControllerApi - functional programming interface
 * @export
 */
export const IndustryControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IndustryControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIndustries(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Industry>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIndustries(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IndustryControllerApi - factory interface
 * @export
 */
export const IndustryControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IndustryControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIndustries(options?: any): AxiosPromise<Array<Industry>> {
            return localVarFp.getIndustries(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IndustryControllerApi - object-oriented interface
 * @export
 * @class IndustryControllerApi
 * @extends {BaseAPI}
 */
export class IndustryControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndustryControllerApi
     */
    public getIndustries(options?: AxiosRequestConfig) {
        return IndustryControllerApiFp(this.configuration).getIndustries(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TagControllerApi - axios parameter creator
 * @export
 */
export const TagControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTags: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TagControllerApi - functional programming interface
 * @export
 */
export const TagControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TagControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTags(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Tag>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTags(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TagControllerApi - factory interface
 * @export
 */
export const TagControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TagControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTags(options?: any): AxiosPromise<Array<Tag>> {
            return localVarFp.getTags(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TagControllerApi - object-oriented interface
 * @export
 * @class TagControllerApi
 * @extends {BaseAPI}
 */
export class TagControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagControllerApi
     */
    public getTags(options?: AxiosRequestConfig) {
        return TagControllerApiFp(this.configuration).getTags(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserControllerApi - axios parameter creator
 * @export
 */
export const UserControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthenticatedUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserControllerApi - functional programming interface
 * @export
 */
export const UserControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuthenticatedUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuthenticatedUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserControllerApi - factory interface
 * @export
 */
export const UserControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthenticatedUser(options?: any): AxiosPromise<User> {
            return localVarFp.getAuthenticatedUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(options?: any): AxiosPromise<Array<User>> {
            return localVarFp.getUsers(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserControllerApi - object-oriented interface
 * @export
 * @class UserControllerApi
 * @extends {BaseAPI}
 */
export class UserControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getAuthenticatedUser(options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).getAuthenticatedUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getUsers(options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).getUsers(options).then((request) => request(this.axios, this.basePath));
    }
}


