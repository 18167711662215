// ExperiencesList.tsx
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { UserControllerApi, IndustryControllerApi, Experience, Industry, Tag, Configuration, User } from 'api';
import { useNavigate } from 'react-router-dom';
import Page from 'components/layout';
import moment from 'moment';
import Spinner from 'components/ui/Spinner';

const ExperiencesList = () => {
	const [users, setUsers] = useState<User[]>([]);
	const [loading, setLoading] = useState(true);

	const navigate = useNavigate();
	const token = localStorage.getItem('token');
	if (!token) {
		navigate('/login');
		return null;
	}
	// TODO make sure it's admin

	const configuration = new Configuration({
		accessToken: token,
	});
	const api = new UserControllerApi(configuration);

	const fetchUsers = () => {
		api
			.getUsers()
			.then((response) => {
				setUsers(response.data || []);
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	useEffect(() => {
		fetchUsers();
	}, []);

	return (
		<Page>
			<div>
				<h1>Manage users</h1>
				{loading && <Spinner />}
				{!loading && (
					<>
						<table className="table table-striped mt-4">
							<thead>
								<tr>
									<th scope="col">Email</th>
									<th scope="col">Name</th>
									<th scope="col">Updated at</th>
									<th scope="col">Edit</th>
								</tr>
							</thead>
							<tbody>
								{users.map((user) => (
									<tr key={user.id}>
										<td>{user.email}</td>
										<td>
											{user.firstName} {user.lastName}
										</td>
										<td>{moment(user.updatedAt).format('MMMM Do YYYY, h:mm:ss a')}</td>
										<td>
											<Link to={`/admin/users/${user.id}`}>Edit</Link>
										</td>
									</tr>
								))}
							</tbody>
						</table>
						<div className="d-flex justify-content-end">
							<Link to="/admin/experiences/new">
								<button type="button" className="btn btn-primary">
									Create New Experience
								</button>
							</Link>
						</div>
					</>
				)}
			</div>
		</Page>
	);
};

export default ExperiencesList;
