const Spinner = () => {
	return (
		<div className="loader-logo-section">
			<img src="/loader.gif" alt="Loader" className="loader-logo" />
			<p className="micro mt-3" role="alert" aria-atomic="true">
				Loading...
			</p>
		</div>
	);
};
export default Spinner;
